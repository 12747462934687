import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { pickBy } from 'lodash'
import { ONE_HOUR_MS, Standard } from '@netpurpose/types'
import { valueIsDefined } from '@netpurpose/utils'
import {
  EntityWithEstimationandSDG,
  EntityWithEstimationsCoverage,
  HoldingWithCoverage,
  HoldingWithCoverageByCompany as HoldingWithCoverageByCompanyBE,
} from '../../generated/facts'
import { GeneratedFactApi } from '../../GeneratedApi'
import { ReverseFieldMap } from '../../queryBuilder'
import { Camelize, snakeToCamelKeys } from '../../utils'
import { getPaginationConfig, useUrlApiConnector } from '../useUrlTableApiConnector'
import { Entity, transformEntity } from './utils'

export type HoldingEntity = Entity & {
  hasResearch: boolean
  hasEstimatedData: boolean
  contributingRevenuePct?: number
}

export type Holding = Omit<Camelize<HoldingWithCoverage['holding']>, 'holdingId' | 'name'> & {
  id: number
  name: string
  entity?: HoldingEntity
  fund?: Camelize<HoldingWithCoverage['fund']>
}

export type HoldingWithCoverageByCompany = Camelize<HoldingWithCoverageByCompanyBE['holding']> & {
  entity?: HoldingEntity
}

export type HoldingWithFilters = Holding &
  HoldingWithCoverageByCompany & {
    'entity.name': string
    'entity.isCovered': boolean
    'entity.hasResearch': boolean
    'entity.alignedSdgs': Standard[]
    'entity.contributingRevenuePct': number
    searchTerm: string
  }

const tableToApiFieldMap: Partial<ReverseFieldMap<keyof HoldingWithFilters>> = {
  name: 'name',
  'entity.name': 'entity.name',
  securityIdentifier: 'security_identifier',
  weight: 'weight',
  'entity.isCovered': 'entity.is_covered',
  'entity.hasResearch': 'entity.has_research',
  'entity.alignedSdgs': 'sdg_goals',
  'entity.contributingRevenuePct': 'entity.contributing_revenue_pct',
  searchTerm: 'search_term',
  holdingsCount: 'holdings_count',
}

const checkEntityHasProperty = (
  entity: unknown,
  property: string,
): entity is Camelize<EntityWithEstimationandSDG> => {
  return typeof entity === 'object' && entity !== null && property in entity
}

const transformHoldingEntity = (
  entity: Camelize<EntityWithEstimationsCoverage | EntityWithEstimationandSDG | undefined>,
) =>
  entity
    ? {
        ...transformEntity(entity),
        hasEstimatedData: entity.hasEstimatedData,
        hasResearch: !!entity.hasResearch,
        ...(checkEntityHasProperty(entity, 'contributingRevenuePct')
          ? {
              contributingRevenuePct: entity.contributingRevenuePct,
            }
          : {}),
      }
    : undefined

const transformHoldingWithCoverage = <HoldingProps extends Camelize<HoldingWithCoverage>>({
  entity,
  holding,
  fund,
}: HoldingProps): Holding => {
  const transformedHoldingEntity = transformHoldingEntity(entity)
  const { holdingId, portfolioId, snapshotId, name, securityIdentifier, identifierType, ...rest } =
    snakeToCamelKeys(holding)

  return {
    ...pickBy(rest, valueIsDefined),
    id: holdingId,
    portfolioId,
    snapshotId,
    name: name || '',
    ...(transformedHoldingEntity ? { entity: transformedHoldingEntity } : {}),
    securityIdentifier,
    identifierType,
    fund,
  }
}

const transformHoldingWithCoverageByCompany = ({
  entity,
  holding,
}: HoldingWithCoverageByCompanyBE): HoldingWithCoverageByCompany => {
  const transformedHoldingEntity = entity
    ? transformHoldingEntity(snakeToCamelKeys(entity))
    : undefined

  return {
    ...snakeToCamelKeys(holding),
    ...(transformedHoldingEntity ? { entity: transformedHoldingEntity } : {}),
  }
}

export const PAGINATED_HOLDINGS_QUERY_CACHE_KEY = 'paginatedHoldings'

export const usePaginatedHoldings = ({
  portfolioId,
  enabled,
}: {
  portfolioId: number
  enabled: boolean
}) => {
  const { queryString, filterConfig, initialPaginationConfig } =
    useUrlApiConnector<HoldingWithFilters>({
      tableToApiFieldMap,
      urlKey: PAGINATED_HOLDINGS_QUERY_CACHE_KEY,
    })

  const { data, ...rest } = useQuery({
    // include portfolioId for easier cache invalidation on ie., portfolio AUM update
    queryKey: [PAGINATED_HOLDINGS_QUERY_CACHE_KEY, queryString, portfolioId],
    queryFn: () =>
      GeneratedFactApi.portfolios.listPortfolioHoldings({
        portfolioId,
        q: queryString,
      }),
    placeholderData: keepPreviousData,
    enabled,
    staleTime: ONE_HOUR_MS,
  })
  const holdings = data?.results?.map((holding) =>
    transformHoldingWithCoverage(snakeToCamelKeys(holding)),
  )

  const paginationConfig = getPaginationConfig({
    numResults: data?.total,
    paginationConfig: initialPaginationConfig,
  })

  return {
    ...rest,
    data: holdings,
    filterConfig,
    paginationConfig,
  }
}

export const PAGINATED_FOF_HOLDINGS_BY_COMPANY_QUERY_CACHE_KEY = 'paginatedFoFHoldingsByCompany'

export const useFoFHoldingsByCompany = ({
  portfolioId,
  enabled,
}: {
  portfolioId: number
  enabled: boolean
}) => {
  const { queryString, filterConfig, initialPaginationConfig } =
    useUrlApiConnector<HoldingWithFilters>({
      tableToApiFieldMap,
      urlKey: PAGINATED_FOF_HOLDINGS_BY_COMPANY_QUERY_CACHE_KEY,
    })

  const { data, ...rest } = useQuery({
    queryKey: [PAGINATED_FOF_HOLDINGS_BY_COMPANY_QUERY_CACHE_KEY, queryString, portfolioId],
    queryFn: () =>
      GeneratedFactApi.portfolios.listPortfolioWithFundsHoldingsByCompany({
        portfolioId,
        q: queryString,
      }),
    placeholderData: keepPreviousData,
    enabled,
    staleTime: ONE_HOUR_MS,
  })
  const holdings = data?.results?.map(transformHoldingWithCoverageByCompany)

  const paginationConfig = getPaginationConfig({
    numResults: data?.total,
    paginationConfig: initialPaginationConfig,
  })

  return {
    ...rest,
    data: holdings,
    filterConfig,
    paginationConfig,
  }
}
